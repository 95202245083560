<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-2">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div
            class="text-center mb-10"
          >
            <v-alert
              type="success"
              prominent
            >
              {{ $t("Bienvenue à PLANETE MARKET") }}
            </v-alert>
            <v-row>
              <v-col>
                <v-btn
                  to="/"
                  block
                  color="primary"
                  outlined
                  class="text-capitalize font-600"
                >
                  {{ $t("Continuer les achats") }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  to="/edit-profile"
                  block
                  color="primary"
                  class="text-capitalize font-600"
                >
                  {{ $t("Complétez votre profil") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-snackbar
          v-model="isSnackbarBottomVisible"
          :color="snackBarColor"
          :light="$vuetify.theme.dark"
          timeout="5000"
        >
          {{ snackBarMessage }}
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapState } from 'vuex'

  export default {

    data () {
      return {
        user: JSON.parse(localStorage.getItem('userData')),
        code: '',
        emailVerifications: 0,
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        valid: false,
        checkbox: false,
        loading: false,
        emailVerified: false,
        loadingEmail: false,
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        codeRules: [v => !!v || i18n.t('Le Code de verification est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        confirmPasswordRules: [v => !!v || i18n.t('Le mot de passe de confirmation est requis')],

      }
    },
    computed: {
      ...mapState({
        displayNotification: 'display_notification',
      }),
    },
    created () {
    },
    methods: {
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarMessage = message
        this.snackBarColor = color
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
